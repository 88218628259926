import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { RetainedCustomerList, agentFlowerNameList, UpdateDistributionStatus } from '@/api/ceshi_xiugai/whole';
export default {
  data: function data() {
    return {
      //系统教程
      tab: 0,
      loading: true,
      course: this.$route.query.course,
      //系统教程
      radio: 1,
      title: '待分配数据',
      value: '',
      tableData: [],
      Alltableoptions: [],
      total: 0,
      visdisplay: false,
      cname: '',
      //客户名字
      start_time: '',
      end_time: '',
      nextVisit: 0,
      next_follow_stime: '',
      next_follow_etime: '',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      list_type: 0,
      //全部表格
      followTime: '',
      NumberEs: 2,
      displaySettings: ['下次回访日期', '执行人'],
      source_client_id: '',
      flower_Array: [],
      queryNM: '',
      Creator: '',
      platform: '',
      Promotion: '',
      address: '',
      wnership: '',
      distribution: []
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.GetList(this.query.page, this.query.pagesize);
    } else {}
  },
  computed: {},
  components: {
    Head: Head
  },
  methods: {
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    },
    GetList: function GetList(page, pagesize) {
      var _this = this;
      this.loading = true;
      RetainedCustomerList({
        page: String(page),
        pagesize: String(pagesize),
        mobile_cname: String(this.queryNM),
        source_client_id: String(this.source_client_id),
        create_stime: String(this.start_time),
        create_etime: String(this.end_time),
        create_realname: this.Creator,
        platform_name: this.platform,
        communication_name: this.Promotion,
        communication_website: this.address,
        mobile_address: String(this.wnership)
      }).then(function (respomse) {
        _this.loading = false;
        respomse.data.data.map(function (item) {
          // if (item.distribution_status == 3) {
          item.distribution_status = '自动';
          // }
        });
        _this.tableData = respomse.data.data;
        _this.query.total = respomse.data.total;
      });
    },
    //时间戳查询
    DataTime: function DataTime() {
      this.NumberEs = Number(0);
      this.query.page = 1;
      if (this.followTime == null) {
        this.start_time = '';
        this.end_time = '';
      } else {
        var s_time = this.followTime[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.followTime[1].toString();
        this.end_time = e_time.substring(0, 10);
      }
    },
    //每页最多显示条数10   20   30   50
    handleSizeChange: function handleSizeChange(val) {
      var _this2 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this2.GetList(_this2.query.page, _this2.query.pagesize);
      });
    },
    //翻页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this3 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this3.GetList(_this3.query.page, _this3.query.pagesize);
      });
    },
    //查询
    QueryClick: function QueryClick() {
      var _this4 = this;
      this.query.page = 1;
      this.$nextTick(function () {
        _this4.GetList(_this4.query.page, _this4.query.pagesize);
      });
    },
    //选中操作
    selsChange: function selsChange(val) {
      var _this5 = this;
      this.Alltableoptions = val;
      this.distribution = [];
      val.forEach(function (item) {
        _this5.distribution.push(item.id);
      });
    },
    ManualAssignment: function ManualAssignment() {
      var _this6 = this;
      this.$confirm('确认将该数据修改为手动方式分配？', '修改分配方式', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        UpdateDistributionStatus({
          ids: _this6.distribution.toString()
        }).then(function (res) {
          _this6.$notify({
            title: '成功',
            message: '手动分配成功',
            type: 'success',
            duration: 2000
          });
          _this6.empty();
          _this6.query.page = 1;
          _this6.GetList(_this6.query.page, _this6.query.pagesize);
        });
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: '修改分配方式'
        });
      });
    },
    flowerArray: function flowerArray() {
      var _this7 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        _this7.flower_Array = res.data;
      });
    },
    empty: function empty() {
      this.queryNM = '';
      this.source_client_id = '';
      this.start_time = '';
      this.end_time = '';
      this.Creator = '';
      this.platform = '';
      this.Promotion = '';
      this.address = '';
      this.wnership = '';
    }
  }
};